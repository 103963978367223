(function($, window, document) { // jQuery wrapper

    /**
     * Ajax Functions
     */
    window.sliders = {

    	/**
    	 * Global load more button functionality
    	 */
        initSlider: function ($el, $args){
        	$el.slick($args);
        },

        /**
         * Products page gallery slider
         */
        homeBanner: function() {
            
            
            $( ".home-banner--images" ).each(function( index ) {

                $el = $(this);
                $args = {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    dots:false,
                    adaptiveHeight: false,
                    autoplay: false
                };

                window.sliders.initSlider($el, $args);
            });

        },
        /**
         * Reviews Carousel
         */
        reviewsCarousel: function() {
            
            
            $( ".reviews-carousel--slider" ).each(function( index ) {

                $el = $(this);
                $args = {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    dots:true,
                    adaptiveHeight: true,
                    autoplay: false,
                };

                window.sliders.initSlider($el, $args);
            });

        },
        /**
         * Google Places Reviews Block
         */
        googlePlacesCarousel: function() {
            
            $( ".google-reviews-block.reviews-loaded" ).each(function( index ) {

                $el = $(this);
                $args = {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    arrows: false,
                    dots:true,
                    adaptiveHeight: true,
                    autoplay: false,
                    responsive: [
                        {
                          breakpoint: 781,
                          settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                          }
                        }
                    ]
                };

                window.sliders.initSlider($el, $args);
            });

        },
        

    };

}(window.jQuery, window, document)); // End of jQuery wrapper