(function($, window, document) { // jQuery wrapper

    /**
     * Ajax Functions
     */
    window.ajax = {

    	/**
    	 * Global load more button functionality
    	 */
        loadMoreButton: function (){
			$('body').on('click', '.load-more-button', function() {

		        var button = $(this),
		            cat = button.data('category'),
		            ppp = button.data('ppp'),
		            cpt = button.data('cpt'),
		            tax = button.data('tax'),
		            term = button.data('term'),
		            order = button.data('order'),
		            orderby = button.data('orderby'),
		            max = button.data('max'),
		            text = button.data('text'),
		            exclude = button.data('exclude'),
		            search = button.data('s'),
		            templateType = button.data('templatetype'),
		            department = button.data('department'),
		            location = button.data('location'),
		            data = {
		              'page' : ajaxVars.current_page,
		              'action': 'more_post_ajax',
		              'order' : order, 
		              'orderby' : orderby,
		              'cpt' : cpt,
		              'cat' : cat,
		              'ppp' : ppp,
		              'tax' : tax,
		              'term' : term,
		              'max' : max,
		              'exclude' : exclude,
		              'search' : search,
		              'location' : location,
		              'department' : department
		            };
	     
	            $.ajax({
	              url : ajaxVars.ajaxurl, // AJAX handler
	              data : data,
	              type : 'POST',
	              beforeSend : function ( xhr ) {
	                button.text('Loading...'); // change the button text
	              },
	              success : function( data ){
	                if( data ) { 
	                  $loadContainer = $('.team-members--team-members-results').find('#ajax-container');
	                  $(data).hide().appendTo($loadContainer).fadeIn(1000);
	                  button.text( text );
	                  ajaxVars.current_page++;
	                                        
	                  if ( ajaxVars.current_page >= max )  {
	                    button.remove(); // if last page, remove the button
	                    $('.load-more-button-container').remove();
	                  }
	         
	                  
	                } else {
	                  button.remove(); // if no data, remove the button as well
	                  $('.load-more-button-container').remove();
	                }
	              }
	            });
	        });
        }

		
    };

}(window.jQuery, window, document)); // End of jQuery wrapper