(function($, window, document) { // jQuery wrapper

    // Page Functions
    window.helpers = {
        
        /**
         * Updates PHP search parameters
         */
        updateSearchParameters: function(uri, key, value) {

          var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
          var separator = uri.indexOf('?') !== -1 ? "&" : "?";
          if (uri.match(re)) {
            return uri.replace(re, '$1' + key + "=" + value + '$2');
          }
          else {
            return uri + separator + key + "=" + value;
          }

        },

       

    };

}(window.jQuery, window, document)); // End of jQuery wrapper