(function($, window, document) { // jQuery wrapper

    // Page Functions
    window.PageFunctions = {
        /**
         *  Init WOW.js
         *  @link https://github.com/matthieua/WOW
         */
        initWOW: function() {
            var wow = new WOW(
              {
                boxClass:     'animate',      // animated element css class (default is wow)
                animateClass: 'animated', // animation css class (default is animated)
                offset:       150,          // distance to the element when triggering the animation (default is 0)
              }
            );
            wow.init();
        },

        /**
         * Loads the social media "share" links into a new, small window
         */
        shareFunctionality: function (){
            $('.share-post a.social-link').click(function(e) {
                e.preventDefault();
                window.open($(this).attr('href'), 'fbShareWindow', 'height=450, width=550, top=' + ($(window).height() / 2 - 275) + ', left=' + ($(window).width() / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
                return false;
            });
        },

        /**
         * Mobile Menu functionality
         */
        mobileMenu: function() {
          $(".menu-trigger-container").on("click", function(e) {
              e.preventDefault();
              if ($('body').hasClass("menu-open")) {
                  $('body').removeClass("menu-open");
              } else {
                  $('body').addClass("menu-open");
              }
          });

          $('.has-mega-menu > a').on("click", function(e) {
              e.preventDefault();
              $(this).parent().toggleClass('open');
              $(this).next().slideToggle();
          });

          $('.mobile-utility-bar--payment-text').on("click", function(e) {
              e.preventDefault();
              $(this).next().slideToggle();
            });
        },

        /**
         * Scroll to anchor links
         */
        scrollToAnchorLinks: function() {
          $(".wrap a[href^='#']").on("click touchstart", function(e) {
              e.preventDefault();
              href = $(this).attr("href");

              if (href.length > 1) {
                  $("html, body").animate(
                      {
                          scrollTop: $(href).offset().top - 165
                      },
                      500
                  );
              }
          });
        },


        /**
         * Updates PHP search parameters
         */
        updateSearchParameters: function(uri, key, value) {

          var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
          var separator = uri.indexOf('?') !== -1 ? "&" : "?";
          if (uri.match(re)) {
            return uri.replace(re, '$1' + key + "=" + value + '$2');
          }
          else {
            return uri + separator + key + "=" + value;
          }

        },

        /**
         * Accordion
         */
        accordion: function() {
            // expand selected accordion
            $('.accordion--title').on('click', function() {
              $(this).next().slideToggle();
              $(this).toggleClass('active');
            });
        },

        /**
         *  Add shrink class to header on scroll
         */
        shrinkHeaderOnScroll: function() {
            var $threshold = 20,
                $window = $(window),
                $height = $window.scrollTop();

            $window.scroll(function() {
                $scroll_position = $window.scrollTop();
                if ($scroll_position > $threshold) {
                    $("body").addClass("shrink");
                } else {
                    $("body").removeClass("shrink");
                }
            });

            // if the scroll position is greater than 0, add the shrink class. This handles a refresh in the middle of the page.
            if ($height > 0) {
                $("body").addClass("shrink");
            }

        },
       

    };

}(window.jQuery, window, document)); // End of jQuery wrapper